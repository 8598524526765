import Hero from "./Hero";
// import ResumeWork from "./ResumeWork";

const Home = () => {
  return (
    <>
      <Hero />
      {/* <ResumeWork /> */}
    </>
  );
};

export default Home;
